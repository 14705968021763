@import "../../../styles/mixins";

.text {
	@include smallText(black);

	@include desktop {
		width: 36vw;
		margin-left: 70rem;
	}
}

.header {
	font-weight: bold;

	@include mobile {
		margin-bottom: $size-small;
	}
}

.header0 {
	font-weight: bold;
	margin-bottom: $size-small;
}

.desc {
	margin-bottom: $size-medium;
	@include desktop {
		margin-top: $size-small;
		margin-bottom: 0;
	}
}

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 2vw;
	padding-right: 2vw;
	margin-bottom: $size-large;
	margin-top: $size-large;

	@include desktop {
		width: auto;
	}

	@include mobile {
		display: none;
	}
}

.mobileContainer {
	@include desktop {
		display: none;
	}
}

.link {
	height: 50vw;
	position: relative;


	@include desktop {
		height: 37vw;
	}

	&:hover {
		transform: scale(1.02);
	}
}

.imageContainer {
	width: 100%;
	display: flex;
	justify-content: center;
}

.image {
	margin-bottom: $size-medium;
	margin-top: $size-small;
	background-color: darkred;

	border-radius: 2px;
	height: 92vw;
	@include elevated;

	@include desktop {
		height: 100%;
		margin-top: 0;

		&:hover {
			@include elevatedHigher;
		}
	}

}

.label {
	@include smallText(white);
	font-weight: bold;
	@include elevatedHigher;
	background-color: grey;
	padding: $size-small;
	width: max-content;

	position: absolute;
	top: 30px;
	border-radius: 2px;

	@include mobile {
		padding: $size-small * 0.8;
		top: -350px;
		left: 10px;
	}

	left: -10px;
	border-top-left-radius: 0;
}

.corner {
	position: absolute;
	height: 20px;
	width: 14px;
	background-size: 100% auto;
	background-size: contain;
	z-index: -1;
	top: -6.3px;
	left: 0;
	background-image: url("../../img/cornerLeft.svg");
}
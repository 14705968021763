@import "../styles/mixins";

.menu {
	margin: $size-medium 0;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;	

	@include mobile {
		margin: $size-small 0;
	}
}

.menuItem {
	@include mediumText(black);
	width: 100%;
	text-align: center;
	padding: $size-tiny/2 0;


	&:hover {
		color: white;
		background-color: darkred;

	}

	&.activePage {
		background-color: $color-grey;
		color: white;

		&:hover {
			background-color: $color-grey;
			color: white;
			box-shadow: none;
		}
	}

	&.marginLeft {
		@include desktop {
			margin-left: $size-small;

		}
	}


	@include desktop {
		display: inline-block;
  		width: auto;
  		padding: 0 $size-medium;
  		text-align: left;
		border-radius: 2px;


  }

}

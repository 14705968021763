@import "mediaQueries";
@import "colors";
//@import url('https://fonts.googleapis.com/css?family=Domine');

@import url('https://fonts.googleapis.com/css?family=Miniver|Spectral');



$font-extra-small-mobile: 14px;
$font-small-mobile: 16px;
$font-medium-mobile: 22px;
$font-large-mobile: 30px;
$font-extra-large-mobile: 36px;

$font-extra-small: 16px;
$font-small: 20px;
$font-medium: 26px;
$font-large: 34px;
$font-extra-large: 52px;

//$default-font: 'Forum', cursive;
$default-font: 'Spectral', serif;
//special-font: 'Abril Fatface', cursive;
$special-font: 'Miniver', cursive;



@mixin text($font-size-mobile, $font-size-desktop, $color, $font-family) {
  font-family: $font-family;
  color: $color;
  line-height: normal;
  


  @include mobile {
    font-size: $font-size-mobile;

  }

  @include desktop {
    font-size: $font-size-desktop;

  }
}



@mixin extraSmallText($color, $font-family:$default-font) {
  @include text($font-extra-small-mobile, $font-extra-small, $color, $font-family);

}

@mixin smallText($color, $font-family:$default-font) {
  @include text($font-small-mobile, $font-small, $color, $font-family);

}

@mixin mediumText($color, $font-family:$default-font) {
  @include text($font-medium-mobile, $font-medium, $color, $font-family);

}

@mixin largeText($color, $font-family:$default-font) {
  @include text($font-large-mobile, $font-large, $color, $font-family);

}

@mixin extraLargeText($color, $font-family:$default-font) {
  @include text($font-extra-large-mobile, $font-extra-large, $color, $font-family);

}

@mixin homePageText($color, $font-size, $font-family:$default-font) {
  @include text($font-size, $font-size, $color, $font-family);

}
$color-blue: #1d1c3e;
$color-red: #b0000e;
$color-grey: grey;
$color-brown: #28170b;

$color-link: $color-blue;
$color-link-hover: yellowgreen;
$link-active: #d10067;

$color-light-overlay: rgba(255, 255, 255, 0.5);

$color-shadow: #222233;

@mixin setTheme($primary, $primary-light, $primary-dark, $overlay) {
  --color-background: #ffffff;
  --color-background-dark: #f9f6ed;
  --color-primary: #{$primary};
  --color-primary-light: #{$primary-light};
  --color-primary-dark: #{$primary-dark};
  --color-overlay: #{$overlay};
  --color-on-background: #000000;
  --color-on-primary: #ffffff;
  --color-on-primary-dark: #ffffff;
  --color-on-primary-light: #ffffff;
  --color-on-error: #ffffff;
  --color-on-rating: #000000;

}

@function colorBackground() {
  @return var(--color-background);
}

@function colorBackgroundDark() {
  @return var(--color-background-dark);
}

@function colorPrimary() {
  @return var(--color-primary);
}

@function colorOverlay() {
  @return var(--color-overlay);
}

@function colorPrimaryLight() {
  @return var(--color-primary-light);
}

@function colorPrimaryDark() {
  @return var(--color-primary-dark);
}

@function colorError() {
  @return var(--color-error);
}

@function colorOnBackground() {
  @return var(--color-on-background);
}

@function colorOnPrimary() {
  @return var(--color-on-primary);
}

@function colorOnPrimaryLight() {
  @return var(--color-on-primary-light);
}

@function colorOnPrimaryDark() {
  @return var(--color-on-primary-dark);
}

@function colorOnError() {
  @return var(--color-on-error);
}

@function colorOnRating() {
  @return var(--color-on-rating);
}

@function colorBackgroundInverted() {
  @return var(--color-on-background);
}

@function colorPrimaryInverted() {
  @return var(--color-on-primary);
}

@function colorPrimaryLightInverted() {
  @return var(--color-on-primary-light);
}

@function colorPrimaryDarkInverted() {
  @return var(--color-on-primary-dark);
}

@function colorErrorInverted() {
  @return var(--color-on-error);
}

@function colorOnBackgroundInverted() {
  @return var(--color-background);
}

@function colorOnPrimaryInverted() {
  @return var(--color-primary);
}

@function colorOnPrimaryLightInverted() {
  @return var(--color-primary-light);
}

@function colorOnPrimaryDarkInverted() {
  @return var(--color-primary-dark);
}

@function colorOnErrorInverted() {
  @return var(--color-error);
}


@mixin setThemeBlackWhite() {
  @include setTheme($primary: grey, $primary-light: white, $primary-dark: black, $overlay: rgba(0, 0, 0, 0.3));
}

@mixin setThemeGreen() {
  @include setTheme($primary: green, $primary-light: lightgreen, $primary-dark: rgba(23, 51, 25, 1), $overlay: rgba(23, 51, 25, 0.3));
}

@mixin setThemeBrown() {
  @include setTheme($primary: brown, $primary-light: gold, $primary-dark: rgba(61, 47, 34, 1), $overlay: rgba(61, 47, 34, 0.3));
}
@import "../../styles/mixins";

.banner {
	margin: 0 0 $size-large 0;
	@include mediumText(white);
	padding: $size-extra-small $size-small;
	text-align: center;

	&.margin {
		margin: $size-medium 0;
	}

	@include mobile {
		margin-bottom: $size-medium;
	}
}



@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}



@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}
@import "./colors";
@import "./sizes";

$shadow-blur-radius: 10px;
$shadow-level-height: 2px;

@mixin elevatedSubtle() {
  box-shadow: $shadow-level-height / 2 $shadow-level-height / 2 $shadow-blur-radius / 2 black;
}

@mixin elevated($color: black) {
  box-shadow: $shadow-level-height $shadow-level-height $shadow-blur-radius $color;
}

@mixin elevatedBottom() {
	&:after {
    content:"";
    position:absolute;
    width:100%;
    left:0;
    bottom:1px;
    z-index:-1;
    transform: scale(0.99);
    box-shadow: 0px 0px 10px 2px black;
}
}


@mixin elevatedHigher() {
  box-shadow: $shadow-level-height * 1.5 $shadow-level-height * 1.5 $shadow-blur-radius black;
  //transform: scale(1.01);
}

@mixin overlapOnParent($width:100%) {
	position: absolute;
	top:0;
	left: 0;
	width: $width;
	height: 100%;
}

@mixin gradientV() {
	height: 100%;
	background: linear-gradient(to bottom, colorBackground() 0%, transparent 70%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=colorBackground(), endColorstr='#00ffffff',GradientType=0 ); 

}

@mixin gradientH() {	
	height: 100%;
	background: linear-gradient(to right, transparent 0%, colorBackground() 80%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr=colorBackground(),GradientType=1 ); 

}

@mixin hpgradientH() {	
	height: 100%;
	background: linear-gradient(to right, colorBackground() 0%, transparent 8%); 
	filter: blur(5px); 

}

@mixin hpgradientV() {
	height: 100%;
	background: linear-gradient(to bottom, colorBackground() 0%, transparent 40%); 
	filter: blur(5px); 

}





//@import "buttons";
@import "colors";
@import "layout";
@import "animations";
@import "sizes";
@import "typography";
@import "wheelMixins";

* {
  box-sizing: border-box;
}


@import "../../../styles/mixins";

.container {
	position: relative;

	background-color: white;
	width: 100%;
	margin-bottom: $size-medium;


	border-radius: 2px;
	padding: $size-small;
	background-color: white;


	&.featured {
		background-color: darkred;
		@include elevatedSubtle;

		@include desktop {
			width: 90%;
		}
	}
	

	&.last {
		margin-bottom: 0;

	}
}



.content {
	@include smallText(black);
	text-align: justify;

	&.featured {
		color: white;
	}

}

.source {
	@include mediumText(black);
	font-weight: bold;
	text-align: left;
	margin-top: $size-tiny;

	&.featured {
		text-align: right;
		color: white;
	}
}


@import "../../styles/mixins";

.container {
	padding: 0 $size-medium;
	@include smallText(black);
	line-height: 1.8;
	text-align: justify;

	@include desktop {
		padding: 0 $size-large;
	}
}
@import "../../styles/mixins";

$outerBorder-w: 4px;
$innerBorder-w: 3px;
$borderSep-w: 6px;


.outerBorder {
	border: $outerBorder-w solid black;
	margin: $size-medium $borderSep-w $borderSep-w $borderSep-w;
	border-radius: 2px;
}

.innerBorder {
	border: $innerBorder-w solid darkred;
	margin: $borderSep-w;
	border-radius: 2px;
	padding-bottom: $size-medium;

}

.whiteBox {
	position: absolute;
	top: $size-small * 1.5;// $size-extra-small;
	width: 100%;
	display: flex;
	justify-content: center;
}

.titleBox {
	position: absolute;
	top: $size-extra-small * 1.6;
	width: 100%;
	display: flex;
	justify-content: center;

	@include desktop {
		top: $size-extra-small * 1.4;

	}
}

.whiteOut {
	background-image: url("../img/mask.svg");
	height: 26px;
	width: 277px;
	background-size: 100% auto;
	background-size: contain;

	@include desktop {
		height: 26px * 1.133;
		width: 277px * 1.133;
	}
}

.title {
	background-color: white;
	@include largeText(black);
	align-self: center;
	//position: absolute;
	margin-top: 2px;
	outline: none;
	
}


.content {

  height: 1000px;





}


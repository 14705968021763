@import "../../../styles/mixins";


.featuredReviews {
	padding: 0 $size-medium;
	display: flex;
	flex-wrap: wrap;
	margin-top: $size-medium * 1.5;

	@include desktop {
		justify-content: center;
		padding: 0 $size-large;


	}
}
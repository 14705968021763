@import "../../../styles/mixins";

.featuredContainer {
	> div {
		margin-bottom: $size-large;

		@include desktop {
			width: 50%;
		}
	}

	@include desktop {
		display: flex;
		width: 100%;
		padding: 0 5%;

		.bannerContainer {
			display: none;
		}
	}

	@include mobile {
		.bannerContainer {
			display: block;
		}
	}
}

.bannerContainer {
	@include mobile {
		display: none;
	}
}

.otherBooksContainer {
	width: 100%;
	padding: 0 $size-medium;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
@import "../../../styles/mixins";



.imageContainer {
	width: 100%;
	display: flex;
	justify-content: center;

	@include desktop {
		display: block;

	}


}

.image {
	margin-bottom: $size-medium;
	background-color: black;

	border-radius: 2px;
	height: 55vw;
	@include elevated;

	@include desktop {
		margin-right: $size-medium;
		max-height: 340px;
		float: left;
		margin-bottom: 0;
	}

}

.byIMG {
		

		&:after {
			clear: both;
			content: "";
			display: table;
			height: $size-medium * 1.4;
		}
	
}


.par {
	margin-top: $size-small;
}
@import "../../styles/mixins";


.footer {
	
 	background-color: black;
  	padding: $size-small;
	@include extraSmallText(white);

	

	
}


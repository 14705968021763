@import "../styles/mixins";
@import "../styles/reset";

html {
  font-size: $size-root-element;



}

body {
	overflow-x: hidden;

}


.page {
  @include setThemeBlackWhite;
}
$size-root-element: 1px;

$size-extra-small: 8rem;
$size-small: $size-extra-small * 2;
$size-medium: $size-extra-small * 4;
$size-large: $size-extra-small * 8;
$size-extra-large: $size-extra-small * 16;
$size-tiny: $size-extra-small / 2;

$module-separation: $size-medium;

////////////////
/// HOMEPAGE ///
////////////////

$hp-container-w: 100vw;
$hp-container-h: 100vh;
$text-rotate: -6deg;
$block-padding: $size-extra-small * 1.4;
$logo-size-mobile: $size-large;
$logo-size-desktop: $size-large * 1.4;




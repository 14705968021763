@import "../../../styles/mixins";

.container {
	padding: 0 $size-medium;
	@include smallText(black);
	line-height: 1.8;
	text-align: justify;

	@include desktop {
		padding: $size-small 15vw;
	}

	@include mobile {
		margin-top: $size-medium;
	}
}

.par {
	margin-top: $size-small;
}

.link {
	color: darkred;
	text-decoration: underline;
}

.testament {
	width: 100%;
	margin-bottom: $size-medium * 1.5;
	display: flex;
	align-items: center;
	flex-direction: column;

	@include desktop {
		margin-bottom: $size-medium;
		flex-direction: row;
		&.odd {
			flex-direction: row-reverse;
		}
	}
}

.text {

}

.image {
	background-color: darkred;
	@include elevated;
	border-radius: 2px;

	@include desktop {
		margin-right: $size-large;

		&.odd {
			margin-left: $size-large;
			margin-right: 0;
		}
	}

	@include mobile {
		margin-bottom: $size-small * 1.5;
		width: 70%;
	}
}

.quote {
	@include smallText(black);
}

.author {
	@include mediumText(black);
	font-weight: bold;
	text-align: left;
	margin-top: $size-tiny;
}

.par0 {
	margin-top: -$size-small;
}
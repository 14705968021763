@import "../../../styles/mixins";

.container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: $size-small;

	@include desktop {
		width: auto;
	}

	&.featured {
		margin-bottom: $size-medium;
	}
}

.link {
	height: 50vw;
	position: relative;


	@include desktop {
		height: 20vw;
	}

	&:hover {
		transform: scale(1.02);
	}

	&.featured {
		height: 60vw;
		@include desktop {
			height: 28vw;
		}
	}
}


.image {
	height: 100%;
	background-color: darkred;
	@include elevated;
	border-radius: 2px;



	&:hover {
		@include elevatedHigher;
	}

	&.featured {
		&:hover {
			@include elevated(darkred);
		}
	}
}


.label {
	@include smallText(white);
	font-weight: bold;
	@include elevatedHigher;
	background-color: darkred;
	padding: $size-small;
	width: max-content;

	position: absolute;
	bottom: -10px;
	border-radius: 2px;

	@include mobile {
		padding: $size-small * 0.8;
	}

	&.onLeft {
		left: -10px;
		border-top-left-radius: 0;

	}

	&.onRight {
		right: -10px;
		border-top-right-radius: 0;
		text-align: right;

	}

}

.corner {
	position: absolute;
	height: 20px;
	width: 14px;
	background-size: 100% auto;
	background-size: contain;
	z-index: -1;
	top: -6.3px;


	&.onLeft {
		left: 0;
		background-image: url("../../img/cornerLeft.svg");

	}
	

	&.onRight {
		right: 0;
		background-image: url("../../img/cornerRight.svg");

	}
}